// https://api.kodepos.dtpsoft.id/api/kodepos/provinsi

import axios from 'axios';
import { config } from 'dotenv';
config()

// Make a POST request
export async function getProvinces () {
    return axios.get('https://api.kodepos.dtpsoft.id/api/kodepos/provinsi', {
        headers: {
            'Authorization': `Basic ${btoa(`${process.env.REACT_APP_KODEPOS_SECRET_KEY}:`)}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Credentials': true
          },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
}
