import apiInstance from './index';
import { config } from 'dotenv';
config()

// Make a POST request
export async function getProducts () {
    return apiInstance.get('/admin/products', {
        headers: {
            'Authorization': `Bearer ${await localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json', 
          },
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
}

export async function getProduct (id) {
  return apiInstance.get(`/admin/products/${id}`, {
      headers: {
          'Authorization': `Bearer ${await localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json', 
        },
  })
  .then((response) => {
    return response
  })
  .catch((error) => {
    return error.response
  });
}

export async function createProduct (payload) {
  return apiInstance.post(`/admin/products/create`, payload, {
      headers: {
          'Authorization': `Bearer ${await localStorage.getItem('jwtToken')}`,
          'Content-Type': 'multipart/form-data',
        },
  })
  .then((response) => {
    return response
  })
  .catch((error) => {
    return error.response
  });
}

export async function updateProduct (id, payload) {
  return apiInstance.put(`/admin/products/${id}`, payload, {
      headers: {
          'Authorization': `Bearer ${await localStorage.getItem('jwtToken')}`,
          'Content-Type': 'multipart/form-data',
        },
  })
  .then((response) => {
    return response
  })
  .catch((error) => {
    return error.response
  });
}

export async function deleteProduct (id) {
  return apiInstance.delete(`/admin/products/${id}`, {
      headers: {
          'Authorization': `Bearer ${await localStorage.getItem('jwtToken')}`,
          'Content-Type': 'multipart/form-data',
        },
  })
  .then((response) => {
    return response
  })
  .catch((error) => {
    return error.response
  });
}
  
