import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message, Upload, Modal } from 'antd';
import { getCategories, createCategory } from '../../api/category';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Form,
  Input,
  Cascader
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import "../../assets/styles/index-table.css";

function NewCategory() {
  const [cascaderData, setCascaderData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [iconFileList, setIconFileList] = useState([]);
  const [bannerFileList, setBannerFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleIconChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setIconFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setIconFileList(newFileList[0].originFileObj);
    }
  };

  const handleBannerChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setBannerFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setBannerFileList(newFileList[0].originFileObj);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.iconFileList;
  };
  const createData = async (values) => {
    setIsLoading(true);
    const { name, slug, parentCategory } = values
    const payload = {
      name,
      slug, 
      parentCategory,
      iconFileList,
      bannerFileList
    }

    const { data } = await createCategory(payload)
    
    if(data.message) {
      if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
        localStorage.removeItem('jwtToken');
        history.push('/login');
      }
      message.error(data.message);
    } else {
      message.success("New Category has been created");
      history.push('/categories');
    }


  }
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await getCategories()
      if(data.message) {
        if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
          localStorage.removeItem('jwtToken');
          history.push('/login');
        }
        message.error(data.message);
      } else {
        const cascaderDataGenerated = data.categories.map((category) => ({
          'value': category.id,
          'label': category.name,
          
        }))
        setCascaderData(cascaderDataGenerated)
      }
    };
    fetchCategories();
  }, [history]);
  
  return (
    <>
      <div className="layout-content">
        <Form
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              maxWidth: '100%',
            }}
            wrapperCol={{ span: 12 }}
            onFinish={createData}
            validateTrigger="onSubmit"
          >
            <Form.Item 
              label="Name" 
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the category name',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              label="Slug" 
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please input the category slug',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              label="Icon" 
              valuePropName="iconFileList" 
              getValueFromEvent={normFile} 
              name="iconFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleIconChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
                onRemove={handleIconChange}
              >
                {iconFileList.length === 0 && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item 
              label="Banner" 
              valuePropName="bannerFileList" 
              getValueFromEvent={normFile} 
              name="bannerFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleBannerChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
              >
                {bannerFileList.length === 0 && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item 
              label="Category" 
              name="parentCategory"
            >
              <Cascader
                options={cascaderData}
                // onChange={handleCascaderChange}
                placeholder="Select an option"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
        </Form>


        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
}

export default NewCategory;
