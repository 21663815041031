import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  BarChartOutlined,
  UnorderedListOutlined,
  MenuUnfoldOutlined,
  ShopOutlined
} from '@ant-design/icons';
import logo from "../../assets/images/logo192.png";

function Sidenav({ color }) {
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Minapoli CMS</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <BarChartOutlined />
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="5">
          Function Pages
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/products">
            <UnorderedListOutlined />
            <span className="label">Products</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/categories">
            <MenuUnfoldOutlined />
            <span className="label">Categories</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/shops">
            <ShopOutlined />
            <span className="label">Shops</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
