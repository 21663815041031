/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Row,
  Col,
  Button
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const history = useHistory();
  useEffect(() => window.scrollTo(0, 0));
  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    history.push('/login');
  }
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="header-control">
          <Button danger onClick={handleLogout}>
            <LogoutOutlined 
              className="logout-logo"
            />
            <span>Logout</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Header;
