import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/products/index";
import Product from "./pages/products/detail";
import NewProduct from "./pages/products/create";
import Categories from "./pages/categories/index";
import Category from "./pages/categories/detail";
import NewCategory from "./pages/categories/create";
import Shops from "./pages/shops/index";
import Shop from "./pages/shops/detail";
import NewShop from "./pages/shops/create";
import SignIn from "./pages/SignIn";
import ProtectedLayoutRoute from "./routes/ProtectedLayoutRoute";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/login" exact component={SignIn} />
        <ProtectedLayoutRoute>
          <Route path="/dashboard" component={Home} />
          <Route path="/products" component={Products} exact/>
          <Route path="/products/:id" component={Product} />
          <Route path="/product/create" component={NewProduct} exact />
          <Route path="/categories" component={Categories} exact />
          <Route path="/categories/:id" component={Category} />
          <Route path="/category/create" component={NewCategory} exact />
          <Route path="/shops" component={Shops} exact />
          <Route path="/shops/:id" component={Shop} />
          <Route path="/shop/create" component={NewShop} exact />
        </ProtectedLayoutRoute>
      </Switch>
    </div>
  );
}

export default App;
