import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createProduct } from '../../api/product';
import { getCategories } from '../../api/category';
import ReactQuill from 'react-quill';
import slugify from 'slugify';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Form,
  Input,
  Cascader,
  InputNumber,
  message, 
  Upload, 
  Modal, 
  Select,
  Switch
} from 'antd';
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import "../../assets/styles/index-table.css";

function NewProduct() {
  const [form] = Form.useForm();
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [cascaderData, setCascaderData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imageFileList, setImageFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState('g');
  const history = useHistory();
  const { Option } = Select;
  const handleCancel = () => setPreviewOpen(false);
  const handleNameChange = (e) => setName(e.target.value)
  const generateSlug = () => {
    const generatedSlug = slugify(name.toLowerCase());
    setSlug(generatedSlug);
    form.setFieldsValue({ slug: generatedSlug })
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleImageChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setImageFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      let newFileList = []
      fileList.forEach((file) => {
        newFileList.push(file.originFileObj)
      })
      setImageFileList(newFileList);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.logoFileList;
  };
  const createData = async (values) => {
    setIsLoading(true);
    const { 
      name,
      unit_price,
      whole_sale_price,
      minimum_order,
      stock,
      description,
      remarks,
      slug,
      is_published,
      is_registered,
      is_promoted,
      category_id
    } = values

    const payload = {
      name,
      unit_of_measurement: unitOfMeasurement,
      unit_price,
      whole_sale_price,
      minimum_order,
      stock: stock ? 1 : 0,
      description,
      remarks,
      slug,
      is_published: is_published ? 1 : 0,
      is_registered: is_registered ? 1 : 0,
      is_promoted: is_promoted ? 1 : 0,
      imageFileList,
      category_id
    }
    const { data } = await createProduct(payload)
    
    if(data.message) {
      if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
        localStorage.removeItem('jwtToken');
        history.push('/login');
      }
      message.error(data.message);
    } else {
      message.success("New Product has been created");
      history.push('/products');
    }


  }
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const filter = (inputValue, path) =>
  path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data } = await getCategories()
      if(data.message) {
        if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
          localStorage.removeItem('jwtToken');
          history.push('/login');
        }
        message.error(data.message);
      } else {
        const cascaderDataGenerated = data.categories.map((category) => ({
          'value': category.id,
          'label': category.name,
          'children':
            category.SubCategories.map(c => ({
              'value': c.id,
              'label': c.name,
            }))
          
        }))
        setCascaderData(cascaderDataGenerated)
      }
    };
    fetchCategories();
  }, [history]);

  return (
    <>
      <div className="layout-content">
        <Form
            form={form}
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              maxWidth: '100%',
            }}
            wrapperCol={{ span: 12 }}
            onFinish={createData}
            validateTrigger="onSubmit"
          >
            <Form.Item 
              label="Name" 
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the name',
                },
              ]}
            >
              <Input onChange={handleNameChange}  />
            </Form.Item>
            <Form.Item 
              label="Slug" 
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please input the slug',
                },
              ]}
            >
              <Input 
                // readOnly
                value={slug}
                onChange={(e) => setSlug(e.target.value)} 
                suffix={
                  <Button type="primary" htmlType="button" onClick={generateSlug}>
                    Generate
                  </Button>
                }
              />
              
            </Form.Item>
            <Form.Item label="Categories" name="category_id">
              <Cascader
                options={cascaderData}
                // onChange={handleCascaderChange}
                placeholder="Select an option"
                showSearch={{
                  filter,
                }}
              />
            </Form.Item>
            <Form.Item 
              label="Unit Price" 
              name="unit_price"
              rules={[
                {
                  required: true,
                  message: 'Please input the Unit Price',
                },
              ]}
            >
              <InputNumber
                defaultValue={0}
                formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // eslint-disable-next-line no-useless-escape
                parser={(value) => value.replace(/\Rp\s?|(,*)/g, '')}
                style={{ width: '50%' }}
                addonAfter={
                  <Select value={unitOfMeasurement} onChange={(value) => setUnitOfMeasurement(value) }>
                    <Option value="g">/Gram</Option>
                    <Option value="kg">/Kilogram</Option>
                    <Option value="ton">/Ton</Option>
                  </Select>
                }
              />
            </Form.Item>
            <Form.Item 
              label="Wholesale Price" 
              name="whole_sale_price"
              rules={[
                {
                  required: true,
                  message: 'Please input the Wholesale Price',
                },
              ]}
            >
              <InputNumber
                formatter={(value) => `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // eslint-disable-next-line no-useless-escape
                parser={(value) => value.replace(/\Rp.\s?|(,*)/g, '')}
                min={0}
                style={{ width: '50%' }}
              />
            </Form.Item>
            <Form.Item 
              label="Minimum Order" 
              name="minimum_order"
              rules={[
                {
                  required: true,
                  message: 'Please input the Minimum Order',
                },
              ]}
            >
              <InputNumber
                defaultValue={0}
                style={{ width: '50%' }}
                addonAfter={
                  <Select value={unitOfMeasurement} onChange={(value) => setUnitOfMeasurement(value) }>
                    <Option value="g">Gram</Option>
                    <Option value="kg">Kilogram</Option>
                    <Option value="ton">Ton</Option>
                  </Select>
                }
              />
            </Form.Item>

            <Form.Item 
              label="Image" 
              valuePropName="imageFileList" 
              getValueFromEvent={normFile} 
              name="imageFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleImageChange}
                multiple={true}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
              >
                { uploadButton }
              </Upload>
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter description' }]}
            >
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item
              name="remarks"
              label="Remarks"
            >
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item
              name="stock"
              label="In Stock?"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="is_promoted"
              label="Promoted?"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="is_registered"
              label="Registered?"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="is_published"
              label="Published?"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
        </Form>


        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
}

export default NewProduct;
