import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Table, message, Input, Button, Space, FloatButton, Popconfirm } from 'antd';
import { getProducts, deleteProduct } from '../../api/product';
import {
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import "../../assets/styles/index-table.css";
import Highlighter from 'react-highlight-words';

function Products() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  // Table Search

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  // End of Table Search

  // Table Delete Data
  const confirm = async (_, id) => {
    const { data } = await deleteProduct(id)
    if(data.message) {
      if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
        localStorage.removeItem('jwtToken');
        history.push('/login');
      }
      message.error(data.message);
    } else {
      message.success("Product has been deleted");
      history.push('/products');
    }
  };
  // End of Table Delete Data
  const renderImage = (text, record, property) => {
    console.log(record[property])
    const image = record[property] && record[property].length > 0 ? record[property][0] : record[property]
    if(image && image.url) {
      return <img alt={image.url} src={`${process.env.REACT_APP_IMAGE_SRC}/products/${image.url}`} style={{ width: 120, height: 120 }} />
    }

    else return "[No Image]"
  }
  const columns = [
    { 
      title: 'Name', 
      dataIndex: 'name',
      key: 'name',
      width: 120,
      ...getColumnSearchProps('name'),
    },
    { 
      title: 'Image', 
      dataIndex: 'Images', 
      key: 'Images',
      render: (text, record) => renderImage(text, record, 'images')
    },
    { 
      title: 'Action',  
      key: 'action',
      render: (text, record) => {
        return (
          <div className="action-wrapper">
            <Popconfirm
              title="Delete the data"
              description="Are you sure to delete this data?"
              onConfirm={(e) => confirm(e, record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ fontSize: '24px' }} className='action-btn remove'/>
            </Popconfirm>
            <EyeOutlined style={{ fontSize: '24px' }} className='action-btn' onClick={() => history.push(`/products/${record.id}`)} />
          </div>
        )
      }
       
    },
  ];
  useEffect(() => {
    console.log(process.env.REACT_APP_CMS_API)
    const fetchData = async () => {
      const { data } = await getProducts()
      if(data.message) {
        message.error(data.message);
      } else {
        const dataWithKey = data.products.map((product) => ({
          ...product,
          key: product.id.toString(),
        }))
        console.log(dataWithKey)
        setData(dataWithKey);
        setLoading(false);
      }
    };

    // Fetch data when the component mounts
    fetchData();
  }, []);

  return (
    <>
      <div className="layout-content">
        <FloatButton
          shape="circle"
          type="primary"
          style={{ right: 50 }}
          icon={<PlusOutlined />}
          onClick={() => history.push('/product/create')}
        />
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{ pageSize: 10 }} // Adjust the pageSize as needed
        />
      </div>
    </>
  );
}

export default Products;
