import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message, Upload, Modal } from 'antd';
import { createShop } from '../../api/shop';
import { getProvinces } from '../../api/location'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Form,
  Input,
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import "../../assets/styles/index-table.css";

function NewShop() {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [logoFileList, setLogoFileList] = useState([]);
  const [bannerFileList, setBannerFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const history = useHistory();
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleLogoChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setLogoFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setLogoFileList(newFileList[0].originFileObj);
    }
  };

  const handleBannerChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setBannerFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setBannerFileList(newFileList[0].originFileObj);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.logoFileList;
  };
  const createData = async (values) => {
    setIsLoading(true);
    const { 
      name,
      slug,
      city,
      province,
      description,
    } = values
    const payload = {
      name,
      slug,
      city,
      province,
      description,
      logoFileList,
      bannerFileList
    }

    const { data } = await createShop(payload)
    
    if(data.message) {
      if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
        localStorage.removeItem('jwtToken');
        history.push('/login');
      }
      message.error(data.message);
    } else {
      message.success("New Shop has been created");
      history.push('/shops');
    }


  }
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  useEffect(() => {
    async function retrieveProvinces() {
      const data = await getProvinces();
      console.log(data)
      setProvinces(data);
    }

    retrieveProvinces();
  })
  return (
    <>
      <div className="layout-content">
        <Form
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              maxWidth: '100%',
            }}
            wrapperCol={{ span: 12 }}
            onFinish={createData}
            validateTrigger="onSubmit"
          >
            <Form.Item 
              label="Name" 
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the name',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              label="Slug" 
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please input the slug',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item 
              label="Logo" 
              valuePropName="logoFileList" 
              getValueFromEvent={normFile} 
              name="logoFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleLogoChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
                onRemove={handleLogoChange}
              >
                {logoFileList.length === 0 && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item 
              label="Banner" 
              valuePropName="bannerFileList" 
              getValueFromEvent={normFile} 
              name="bannerFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleBannerChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
              >
                {bannerFileList.length === 0 && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item 
              label="City" 
              name="city"
              rules={[
                {
                  required: true,
                  message: 'Please input the city',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              label="Province" 
              name="province"
              rules={[
                {
                  required: true,
                  message: 'Please input the province',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please enter description' }]}
            >
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
        </Form>


        <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
}

export default NewShop;
