import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Drawer, Affix } from "antd";
import { checkToken } from '../api/auth';
import Sidenav from "../components/layout/Sidenav";
import Header from "../components/layout/Header";
const { Header: AntHeader, Content, Sider } = Layout;

const ProtectedLayoutRoute = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] = useState("right");
    const [sidenavColor, setSidenavColor] = useState("#1890ff");
    const [sidenavType, setSidenavType] = useState("transparent");
    const [fixed, setFixed] = useState(false);


    const openDrawer = () => setVisible(!visible);
    const handleSidenavType = (type) => setSidenavType(type);
    const handleSidenavColor = (color) => setSidenavColor(color);
    const handleFixedNavbar = (type) => setFixed(type);

    let { pathname } = useLocation();
    pathname = pathname.replace("/", "");
    const history = useHistory();

    useEffect(() => {
        if (pathname === "rtl") {
            setPlacement("left");
        } else {
            setPlacement("right");
        }
        const checkAndRedirect = async () => {
        try {
            const token = await localStorage.getItem('jwtToken');
            const sessionCheck = await checkToken(token)
            if (sessionCheck.status === 403) {
                // setIsAuthenticated(false);
                history.push('/login');
            } else {
                // setIsAuthenticated(true);
            }
        } catch (error) {
            console.error('Error checking session:', error);
        }
        };

        checkAndRedirect();
    }, [history, pathname]);

    return (
        
        <Layout
        className={`layout-dashboard ${
            pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
        >
        <Drawer
            title={false}
            placement={placement === "right" ? "left" : "right"}
            closable={false}
            onClose={() => setVisible(false)}
            open={visible}
            key={placement === "right" ? "left" : "right"}
            width={250}
            className={`drawer-sidebar ${
            pathname === "rtl" ? "drawer-sidebar-rtl" : ""
            } `}
        >
            <Layout
            className={`layout-dashboard ${
                pathname === "rtl" ? "layout-dashboard-rtl" : ""
            }`}
            >
            <Sider
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${
                sidenavType === "#fff" ? "active-route" : ""
                }`}
                style={{ background: sidenavType }}
            >
                <Sidenav color={sidenavColor} />
            </Sider>
            </Layout>
        </Drawer>
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
            }}
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
        >
            <Sidenav color={sidenavColor} />
        </Sider>
        <Layout>
            {fixed ? (
            <Affix>
                <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                />
                </AntHeader>
            </Affix>
            ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                />
            </AntHeader>
            )}
            <Content className="content-ant">{children}</Content>
        </Layout>
        </Layout>
    );
};

export default ProtectedLayoutRoute;
