import apiInstance from './index';
import { config } from 'dotenv';
config()

// Make a POST request
export function login (payload) {
    return apiInstance.post('/login', payload)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error.response
    });
}

export function checkToken(token) {
    return apiInstance.get('/api/check-session', {
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json', 
        },
  })
  .then((response) => {
    return response
  })
  .catch((error) => {
    return error.response
  });
}
  
