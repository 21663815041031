import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { message, Upload, Modal } from 'antd';
import { getShop, updateShop } from '../../api/shop';
import { getProvinces } from '../../api/location';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Form,
  Input,
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import "../../assets/styles/index-table.css";

function Shop() {
  const { id } = useParams();
  const [shop, setShop] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [logoFileList, setLogoFileList] = useState([]);
  const [bannerFileList, setBannerFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const history = useHistory();
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
        
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.logoFileList;
  };
  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const handleLogoChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setLogoFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setLogoFileList(newFileList[0].originFileObj);
    }
  };

  const handleBannerChange = ({ fileList }) => {
    if(!fileList || fileList.length === 0) {
      setBannerFileList([]);
    } else if(fileList && fileList.length > 0) {
      // Limit to only one file
      const newFileList = fileList.slice(-1);
      setBannerFileList(newFileList[0].originFileObj);
    }
  };
  const updateData = async (values) => {
    setIsLoading(true);
    const { 
      name,
      slug,
      city,
      province,
      description,
     } = values
     
    let payload = {
      name,
      slug,
      city,
      province,
      description,
    }

    if(!logoFileList[0]) payload = {...payload, logoFileList}
    if(!bannerFileList[0]) payload = {...payload, bannerFileList}

    console.log(payload)

    const { data } = await updateShop(id, payload)
    
    if(data.message) {
      if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
        localStorage.removeItem('jwtToken');
        history.push('/login');
      }
      message.error(data.message);
    } else {
      message.success("Shop has been updated");
      history.push('/shops');
    }


  }
  useEffect(() => {
    
    const fetchData = async () => {
      const { data } = await getShop(id)
      if(data.message) {
        if(data.message === 'Invalid Token' || data.message === 'Authentication required') {
          localStorage.removeItem('jwtToken');
          history.push('/login');
        }
        message.error(data.message);
      } else {
        setLogoFileList([{ uid: '1', name: data.shop.logo, status: 'done', url: `${process.env.REACT_APP_IMAGE_SRC}/shops/${data.shop.logo}` }])
        setBannerFileList([{ uid: '2', name: data.shop.banner, status: 'done', url: `${process.env.REACT_APP_IMAGE_SRC}/shops/${data.shop.banner}` }])
        setShop(data.shop);
      }
    };
    const fetchProvinces = async () => {
      const { data } = await getProvinces()
      console.log(data)
    }
    fetchData();
    // fetchProvinces();
  }, [history, id]);

  return (
    <>
      <div className="layout-content">
        { shop && 
          <Form
            initialValues={{
              name: shop.name,
              slug: shop.slug,
              city: shop.city,
              province: shop.province,
              description: shop.description,
            }}
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              maxWidth: '100%',
            }}
            wrapperCol={{ span: 12 }}
            onFinish={updateData}
            validateTrigger="onSubmit"
          >
          <Form.Item 
            label="Name" 
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input the name',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            label="Slug" 
            name="slug"
            rules={[
              {
                required: true,
                message: 'Please input the slug',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item 
              label="Logo" 
              valuePropName="logoFileList" 
              getValueFromEvent={normFile} 
              name="logoFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleLogoChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
                onRemove={(fileList) => handleLogoChange(fileList)}
                defaultFileList={logoFileList}
              >
                {logoFileList.length === 0 && uploadButton}
              </Upload>
          </Form.Item>
          
          <Form.Item 
              label="Banner" 
              valuePropName="bannerFileList" 
              getValueFromEvent={normFile} 
              name="bannerFileList"
            >
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleBannerChange}
                limit={1}
                multiple={false}
                tooltipVisible={false}
                beforeUpload={()=>{return false}}
                onRemove={(fileList) => handleBannerChange(fileList)}
                defaultFileList={bannerFileList}
              >
                {bannerFileList.length === 0 && uploadButton}
              </Upload>
          </Form.Item>
          <Form.Item 
            label="City" 
            name="city"
            rules={[
              {
                required: true,
                message: 'Please input the city',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item 
            label="Province" 
            name="province"
            rules={[
              {
                required: true,
                message: 'Please input the province',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter description' }]}
          >
            <ReactQuill theme="snow" />
          </Form.Item>
            <Form.Item wrapperCol={{ offset: 3, span: 12 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        }
        <Modal open={previewOpen} title={''} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </div>
    </>
  );
}

export default Shop;
